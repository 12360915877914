































import { IUser } from '@/models/user';
import router from '@/router';
import store, { IObjectState } from '@/store';
import { IAuthState } from '@/store/modules/auth';
import { IStateUser } from '@/store/modules/users';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { IAuth } from '../models/auth';
import { IAuthTranslations } from '../translations';
// @ is an alias to /src
@Component({
  name: 'UserProfile',
  components: {},
})
class UserProfile extends Vue {
  @Getter('currentUser') public user!: IUser;

  @Getter('userAuthState') public authState!: IAuthState;

  @Mutation('setUserLogout') public logout!: () => void;

  @Mutation('setUserLogin') public login!: (auth: IAuth) => void;

  @Getter('getAuthTranslations') public translations!: IAuthTranslations;
  public previousAuth!: IAuth;
  public router = router;
  public created() {
    const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
    this.previousAuth = { user: { ...this.user }, token: this.authState.token };
    localStorage.setItem(
      localStorageProp,
      JSON.stringify({
        user: this.user,
        userLoggedIn: false,
      })
    );
    this.logout();
  }
  public loginUser() {
    const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
    this.login(this.previousAuth);
    localStorage.setItem(localStorageProp, JSON.stringify(this.previousAuth));
    router.push('/');
  }
}

export default UserProfile;
